<template>
  <section class="VM2L-section">
    <div class="inner-content">
      <h2>Quem pode se interessar pelo método <strong>VM2L</strong>?</h2>

      <carousel :breakpoints="breakpoints" v-if="isMobile">
        <slide v-for="slide in slides" :key="slide">
          <div class="carousel__item">
            <img :src="slide.img"
              alt="Imagem">
            <p>{{ slide.text }}</p>
          </div>
        </slide>

        <template #addons>
          <navigation />
          <pagination />
        </template>
      </carousel>

      <div class="lst-who-is-it-for" v-if="!isMobile">
        <div>
          <img src="@/assets/section-3/img-1.png"
            alt="Imagem de um menino com um Tablet com a mão para cima.">
          <p>Todos os pais que desejam potencializar as habilidades e
             talentos dos seus filhos através da iniciação musical
              em uma plataforma única e online.</p>
        </div>
        <div>
          <img src="@/assets/section-3/img-2.png"
            alt="Imagem de um menino com um Tablet">
          <p>Para todas as crianças de 2 a 12 anos de idade que
            já escutam músicas e assistem desenhos animados
            e precisam de um empurrãozinho para aproveitar
            ao máximo esses conteúdos que já são sucessos em sua casa.</p>
        </div>
        <div>
          <img src="@/assets/section-3/img-3.png"
            alt="Imagem de um menino com um Tablet com a mão para cima.">
          <p>Para qualquer criança cantar afinado seguindo um passo a
             passo de conhecimento musical, enquanto educa o ouvido.</p>
        </div>
        <div>
          <img src="@/assets/section-3/img-4.png"
            alt="Imagem de um menino com um Tablet com a mão para cima.">
          <p>Para aqueles que não possuem instrumentos musicais em casa ou querem
            simplesmente a improvisação de emitir sons com a própria voz.
            Conheça nosso super método BeatBox em família.</p>
        </div>
        <div>
          <img src="@/assets/section-3/img-5.png"
            alt="Imagem de um menino com um Tablet com a mão para cima.">
          <p>Para as crianças da primeira infância que já apresentam déficits
             de atenção e necessitam de um método que melhore sua capacidade de foco.</p>
        </div>
        <div>
          <img src="@/assets/section-3/img-6.png"
            alt="Imagem de um menino com um Tablet com a mão para cima.">
          <p>Para os amantes de linguagem “gamificada” com aplicação
            no ensino da música (canto, percepção musical e leitura de partitura).</p>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import 'vue3-carousel/dist/carousel.css';
import {
  Carousel,
  Slide,
  Pagination,
  Navigation,
} from 'vue3-carousel';

export default {
  name: 'WhoIsItFor',
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data() {
    return {
      isMobile: window.matchMedia('(max-width: 768px)').matches,
      slides: [
        {
          img: require('@/assets/section-3/img-1.png'), // eslint-disable-line global-require
          text: 'Todos os pais que desejam potencializar as habilidades e talentos dos seus filhos através da iniciação musical em uma plataforma única e online.',
        },
        {
          img: require('@/assets/section-3/img-2.png'), // eslint-disable-line global-require
          text: 'Para todas as crianças de 2 a 12 anos de idade que já escutam músicas e assistem desenhos animados e precisam de um empurrãozinho para aproveitar ao máximo esses conteúdos que já são sucessos em sua casa.',
        },
        {
          img: require('@/assets/section-3/img-3.png'), // eslint-disable-line global-require
          text: 'Para qualquer criança cantar afinado seguindo um passo a passo de conhecimento musical, enquanto educa o ouvido.',
        },
        {
          img: require('@/assets/section-3/img-4.png'), // eslint-disable-line global-require
          text: 'Para aqueles que não possuem instrumentos musicais em casa ou querem simplesmente a improvisação de emitir sons com a própria voz. Conheça nosso super método BeatBox em família.',
        },
        {
          img: require('@/assets/section-3/img-5.png'), // eslint-disable-line global-require
          text: 'Para as crianças da primeira infância que já apresentam déficits de atenção e necessitam de um método que melhore sua capacidade de foco.',
        },
        {
          img: require('@/assets/section-3/img-6.png'), // eslint-disable-line global-require
          text: 'Para os amantes de linguagem “gamificada” com aplicação no ensino da música (canto, percepção musical e leitura de partitura).',
        },
      ],
      breakpoints: {
        // 700px and up
        700: {
          itemsToShow: 2,
          snapAlign: 'center',
        },
        // 1024 and up
        1024: {
          itemsToShow: 2,
          snapAlign: 'start',
        },
      },
    };
  },
};
</script>
<style lang="scss" scoped>
  section {
    background: linear-gradient(180deg, #012667 0%, #B517FF 100%);
    position: relative;

    &:after {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      max-width: 100%;
      max-height: 100%;
      background: url(@/assets/section-3/background.png) center center no-repeat;
      background-size: contain;
      z-index: 1;

      @media screen {
        @media (max-width:768px) {
          display: none;
        }
      }
    }
  }
  h2 {
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 900;
    line-height: 136.523%;
    letter-spacing: -1.44px;
    text-transform: uppercase;
    margin-top: 56px;

    @media screen {
      @media (max-width:768px) {
        margin-top: 0;
        font-size: 28px;
      }
    }

    strong {
      color: #0FF;
      font-size: 48px;
      font-style: normal;
      font-weight: 900;
      line-height: 136.523%;
      letter-spacing: -1.92px;

      @media screen {
        @media (max-width:768px) {
          font-size: 33px;
        }
      }
    }
  }
  .lst-who-is-it-for {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 88px;
    img {
      max-width: 321px;
      display: block;
      position: relative;
      top: -60px;
    }

    > div {
      flex: 0 0 322px;
      margin: 12px 12px 60px 12px;
      padding: 18px;
      border: 1px solid #F3F4FE;
      border-radius: 10px;
      background: rgba(255, 255, 255, 0.20);
      filter: drop-shadow(0px 50px 115px rgba(165, 153, 209, 0.30));
    }
  }

  p {
    position: relative;
    top: -40px;
    color: #FFF;
    text-align: justify;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 20.8px */
  }

  .carousel__item {
    p {
      position: static;
      width: 90%;
      margin: 10px auto;
    }
  }
</style>
