<template>
  <router-view/>
</template>

<style lang="scss">
/* Small CSS Reset */

/* Normalize default margins and paddings */
body, h1, h2, h3, p, ul, ol, li, figure, figcaption, blockquote {
  margin: 0;
  padding: 0;
}

/* Normalize font sizes */
body {
  font-size: 16px; /* Set a base font size (adjust as needed) */
}

/* Optional: Reset list styles */
ul, ol {
  list-style: none;
}

.clearfix::after {
  content: "";
  display: table;
  clear: both;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background: #012667;
  color: #fff;
  overflow-x: hidden;

  text-align: center;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 136.523%; /* 32.765px */
  letter-spacing: -0.96px;
}

section {
  .inner-content {
    width: 100vw;
    max-width: 1280px;
    margin: 0 auto;
  }
  section.carousel {
    background: none;
    padding-bottom: 0;
    &:after {
      display: none;
    }
  }

  h2 {
    word-spacing: 7px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}

.action-button {
  border-radius: 16px;
  border: 2px solid #0FF;
  background: linear-gradient(90deg, #3E66AA 0%, #00276C 100%);

  color: #FFF;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.96px;
  text-transform: uppercase;
  text-decoration: none;

  display: flex;
  width: 90%;
  max-width: 612px;
  height: 98px;
  line-height: 98px;
  padding: 10px;
  display: block;
  margin: 58px auto 0;
}

.carousel__item {
  min-height: 200px;
  width: 100%;
  border: 1px solid #F3F4FE;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.2);
  filter: drop-shadow(0px 50px 115px rgba(165, 153, 209, 0.3));
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px;

  img {
    width: 100%;
    margin: 0 auto;
    display: block;
  }
}

.carousel__slide {
  padding: 10px;
  overflow: hidden;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white !important;
  background: #fff !important;
  border-radius: 50% !important;
  box-shadow: 0 0 10px #000 !important;
}

.carousel__item {
  p {
    text-align: justify;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 20.8px */
    margin-top: 20px;
    padding: 0 20px 20px;
  }
}

.the-method {
  .carousel__slide {
    &:nth-child(2n) {
      .carousel__item {
        background: #0c2176;
      }
    }
    .carousel__item {
      background: rgb(56 85 162);
    }
  }
}

.ytp-title-enable-channel-logo .ytp-title {
  pointer-events: none !important;
}
</style>
