<!-- eslint-disable max-len -->
<template>
  <section class="VM2L-section">
    <div class="inner-content">
      <h2>Quais os benefícios do método <strong>vm2l</strong> do curso <span style="text-wrap: nowrap;">Look Sonata</span>?</h2>

      <carousel :breakpoints="breakpoints" v-if="isMobile">
        <slide v-for="slide in slides" :key="slide">
          <div class="carousel__item">
            <img :src="slide.img"
              alt="Imagem">
            <h3>{{ slide.title }}</h3>
            <p>{{ slide.text }}</p>
          </div>
        </slide>

        <template #addons>
          <navigation />
          <pagination />
        </template>
      </carousel>

      <div class="lst-benefits" v-if="!isMobile">
        <div class="box-img">
          <img src="@/assets/section-4/img-1.png"
          alt="Ilustração">
        </div>
        <div class="box-text">
          <h3>Compreensão da Música</h3>
          <p> O método ajuda as crianças a visualizarem a lógica matemática por trás das músicas, tornando a linguagem musical facilmente acessível.</p>
        </div>

        <div class="box-text">
          <h3>Desenvolvimento da Coordenação Motora</h3>
          <p>A interação com ritmos e padrões musicais melhora a coordenação motora das crianças.</p>
        </div>
        <div class="box-img">
          <img src="@/assets/section-4/img-2.png"
          alt="Ilustração">
        </div>

        <div class="box-img">
          <img src="@/assets/section-4/img-3.png"
          alt="Ilustração">
        </div>
        <div class="box-text">
          <h3>ALFABETIZAÇÃO MUSICAL</h3>
          <p>Além de ouvir músicas, as crianças aprendem a "ler" e compreender a música em tempo real.</p>
        </div>

        <div class="box-text">
          <h3>estímulo da matemática e linguística</h3>
          <p>Através da identificação de padrões e da lógica nas músicas, as habilidades matemáticas e linguísticas das crianças são estimuladas.</p>
        </div>
        <div class="box-img">
          <img src="@/assets/section-4/img-4.png"
          alt="Ilustração">
        </div>

        <div class="box-text">
          <h3>FORTALECIMENTO DA CONCENTRAÇÃO</h3>
          <p>O método ajuda as crianças a focar e prestar atenção em padrões e variações nas músicas.</p>
        </div>
        <div class="box-img">
          <img src="@/assets/section-4/img-5.png"
          alt="Ilustração">
        </div>

        <div class="box-img">
          <img src="@/assets/section-4/img-6.png"
          alt="Ilustração">
        </div>
        <div class="box-text">
          <h3>ESTIMULAÇÃO DA INTELIGÊNCIA</h3>
          <p>A interação com a música de forma lógica e estruturada pode estimular a inteligência das crianças.</p>
        </div>

        <div class="box-text">
          <h3>desenvolvimento da capacidade inventiva</h3>
          <p>Ao compreender os padrões e variações por trás das músicas, as crianças se tornam mais inventivas e criativas.</p>
        </div>
        <div class="box-img">
          <img src="@/assets/section-4/img-7.png"
          alt="Ilustração">
        </div>

        <div class="box-img">
          <img src="@/assets/section-4/img-8.png"
          alt="Ilustração">
        </div>
        <div class="box-text">
          <h3>APRIMORAMENTO DA comunicação</h3>
          <p>A música ajuda as crianças a se expressarem melhor, aumentando a autoestima.</p>
        </div>

        <div class="box-text">
          <h3>desenvolvimento dO TATO FINO</h3>
          <p>A interação com instrumentos ou mesmo com ritmos realizados com as mãos ajuda no desenvolvimento do tato.</p>
        </div>
        <div class="box-img">
          <img src="@/assets/section-4/img-9.png"
          alt="Ilustração">
        </div>

        <div class="box-img">
          <img src="@/assets/section-4/img-10.png"
          alt="Ilustração">
        </div>
        <div class="box-text">
          <h3>MELHORIA DA PERCEPÇÃO SONORA</h3>
          <p>As crianças aprendem a distinguir e compreender diferentes sons e ritmos.</p>
        </div>

        <div class="box-text">
          <h3>desenvolvimento dA PERCEPÇÃO ESPACIAL</h3>
          <p>Ao entender onde os sons se encaixam e como se relacionam, as crianças podem desenvolver uma melhor percepção espacial.</p>
        </div>
        <div class="box-img">
          <img src="@/assets/section-4/img-11.png"
          alt="Ilustração">
        </div>

        <div class="box-img">
          <img src="@/assets/section-4/img-12.png"
          alt="Ilustração">
        </div>
        <div class="box-text">
          <h3>APRECIAÇÃO ESTÉTICA</h3>
          <p>Através da música e da compreensão de sua beleza estrutural, as crianças desenvolvem uma apreciação estética.</p>
        </div>
      </div>
      <a href="javascript:;" @click="this.$emit('openModal');" class="action-button">Quero inscrever meu filho(a) agora</a>
    </div>
  </section>
</template>
<script>
import 'vue3-carousel/dist/carousel.css';
import {
  Carousel,
  Slide,
  Pagination,
  Navigation,
} from 'vue3-carousel';

export default {
  name: 'WhatBenefits',
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data() {
    return {
      isMobile: window.matchMedia('(max-width: 768px)').matches,
      slides: [
        {
          img: require('@/assets/section-4/img-1.png'), // eslint-disable-line global-require
          title: 'Compreensão da Música',
          text: ' O método ajuda as crianças a visualizarem a lógica matemática por trás das músicas, tornando a linguagem musical facilmente acessível.',
        },
        {
          title: 'Desenvolvimento da Coordenação Motora',
          text: 'A interação com ritmos e padrões musicais melhora a coordenação motora das crianças.',
          img: require('@/assets/section-4/img-2.png'), // eslint-disable-line global-require
        },
        {
          img: require('@/assets/section-4/img-3.png'), // eslint-disable-line global-require
          title: 'ALFABETIZAÇÃO MUSICAL',
          text: 'Além de ouvir músicas, as crianças aprendem a "ler" e compreender a música em tempo real.',
        },
        {
          title: 'estímulo da matemática e linguística',
          text: 'Através da identificação de padrões e da lógica nas músicas, as habilidades matemáticas e linguísticas das crianças são estimuladas.',
          img: require('@/assets/section-4/img-4.png'), // eslint-disable-line global-require
        },
        {
          title: 'FORTALECIMENTO DA CONCENTRAÇÃO',
          text: 'O método ajuda as crianças a focar e prestar atenção em padrões e variações nas músicas.',
          img: require('@/assets/section-4/img-5.png'), // eslint-disable-line global-require
        },
        {
          img: require('@/assets/section-4/img-6.png'), // eslint-disable-line global-require
          title: 'ESTIMULAÇÃO DA INTELIGÊNCIA',
          text: 'A interação com a música de forma lógica e estruturada pode estimular a inteligência das crianças.',
        },
        {
          title: 'desenvolvimento da capacidade inventiva',
          text: 'Ao compreender os padrões e variações por trás das músicas, as crianças se tornam mais inventivas e criativas.',
          img: require('@/assets/section-4/img-7.png'), // eslint-disable-line global-require
        },
        {
          img: require('@/assets/section-4/img-8.png'), // eslint-disable-line global-require
          title: 'APRIMORAMENTO DA comunicação',
          text: 'A música ajuda as crianças a se expressarem melhor, aumentando a autoestima.',
        },
        {
          title: 'desenvolvimento dO TATO FINO',
          text: 'A interação com instrumentos ou mesmo com ritmos realizados com as mãos ajuda no desenvolvimento do tato.',
          img: require('@/assets/section-4/img-9.png'), // eslint-disable-line global-require
        },
        {
          img: require('@/assets/section-4/img-10.png'), // eslint-disable-line global-require
          title: 'MELHORIA DA PERCEPÇÃO SONORA',
          text: 'As crianças aprendem a distinguir e compreender diferentes sons e ritmos.',
        },
        {
          title: 'desenvolvimento dA PERCEPÇÃO ESPACIAL',
          text: 'Ao entender onde os sons se encaixam e como se relacionam, as crianças podem desenvolver uma melhor percepção espacial.',
          img: require('@/assets/section-4/img-11.png'), // eslint-disable-line global-require
        },
        {
          img: require('@/assets/section-4/img-12.png'), // eslint-disable-line global-require
          title: 'APRECIAÇÃO ESTÉTICA',
          text: 'Através da música e da compreensão de sua beleza estrutural, as crianças desenvolvem uma apreciação estética.',
        },
      ],
      breakpoints: {
        // 700px and up
        700: {
          itemsToShow: 2,
          snapAlign: 'center',
        },
        // 1024 and up
        1024: {
          itemsToShow: 2,
          snapAlign: 'start',
        },
      },
    };
  },
};
</script>
<style lang="scss" scoped>
  section {
    background: linear-gradient(180deg, #B517FF 0%, #012667 100%);
  }
  h2 {
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 900;
    line-height: 136.523%;
    letter-spacing: -1.44px;
    text-transform: uppercase;

    @media screen {
      @media (max-width:768px) {
        font-size: 28px;
        padding-top: 40px;
      }
    }

    strong {
      color: #0FF;
      font-size: 48px;
      font-style: normal;
      font-weight: 900;
      line-height: 136.523%;
      letter-spacing: -1.92px;

      @media screen {
        @media (max-width:768px) {
          font-size: 33px;
        }
      }
    }
  }

  @media screen {
    @media (max-width:768px) {
      .action-button {
        font-size: 22px;
        line-height: 25px;
        align-items: center;
        display: flex;
      }
    }
  }

  .lst-benefits {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    margin: 44px auto 0;
    text-align: left;
    h3 {
      color: #A0C6FF;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      font-size: 28px;
      font-style: normal;
      font-weight: 800;
      line-height: 136.523%; /* 43.687px */
      letter-spacing: -1.28px;
      text-transform: uppercase;
    }

    p {
      color: #FFF;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 136.523%;
      letter-spacing: -0.84px;
    }

    img {
      width: 100%;
      height: auto;
      display: block;
    }

    .box-img {
      flex: 0 0 287px;
      max-width: 287px;
    }

    .box-text {
      flex: 0 0 calc(100% - 349px);
      border: 1px solid #FFF;
      border-radius: 7px;
      background: #730DA3;
      padding: 5px 12px;
      height: 100%;
      min-height: 95px;
    }

    > div {
      margin: 9px;
    }
  }
  .carousel__item {
    h3 {
      text-transform: uppercase;
    }
    p {
      text-align: justify;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 130%; /* 20.8px */
      margin-top: 20px;
      padding: 0 20px 20px;
    }
    img {
      top: 0;
      margin-bottom: 20px;
    }
  }
</style>
