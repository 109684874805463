<!-- eslint-disable max-len -->
<template>
  <section class="VM2L-section the-method">
    <div class="inner-content">
      <h2>O MÉTODO INOVADOR E DIVERTIDO <span>VM2L</span> utilizado no curso <span>Look Sonata</span> educa as crianças
        a compreender a lógica matemática por trás das músicas, baseando-se em <strong>4 PILARES:</strong></h2>
      <div class="container-star">
        <div class="left-column" v-if="!isMobile">
          <div class="left-box box-vision">
            <h3>Visão</h3>
            <p>A linguagem musical em formato <strong>VISUAL</strong></p>
          </div>
          <div class="left-box box-math">
            <h3>Matemática</h3>
            <p>A <strong>MATEMÁTICA</strong> compreendida através da música.</p>
          </div>
        </div>
        <div class="center-box">
          <img alt="VM2L Imagem" src="../assets/section-2/star.png" />
        </div>
        <carousel :breakpoints="breakpoints" v-if="isMobile">
          <slide v-for="slide in slides" :key="slide">
            <div class="carousel__item" v-html="slide.html"></div>
          </slide>

          <template #addons>
            <navigation />
            <pagination />
          </template>
        </carousel>
        <div class="right-column" v-if="!isMobile">
          <div class="right-box box-logic">
            <h3>Lógica</h3>
            <p>A <strong>LÓGICA</strong> da composição das músicas por meio de padrões e variações que encantam.</p>
          </div>
          <div class="right-box box-ludic">
            <h3>Lúdico</h3>
            <p>O aprendizado de forma <strong>LÚDICA</strong> e divertida para seus filhos.</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import 'vue3-carousel/dist/carousel.css';
import {
  Carousel,
  Slide,
  Pagination,
  Navigation,
} from 'vue3-carousel';
/* eslint-disable */
export default {
  name: 'WhatBenefits',
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data() {
    return {
      isMobile: window.matchMedia('(max-width: 768px)').matches,
      slides: [
        {
          html: `<div class="left-box box-vision">
            <h3>
              <img src="${require('../assets/section-2/img-1.png')}" style="width: 30%;margin: 20px auto;" />
              Visão</h3>
            <p>A linguagem musical em formato <strong>VISUAL</strong></p>
          </div>`,
        },
        {
          html: `
            <div class="right-box box-logic">
              <h3>
                <img src="${require('../assets/section-2/img-3.png')}" style="width: 30%;margin: 20px auto;" />
                Lógica</h3>
              <p>A <strong>LÓGICA</strong> da composição das músicas por meio de padrões e variações que encantam.</p>
            </div>`,
        },
        {
          html: `<div class="left-box box-math">
            <h3>
              <img src="${require('../assets/section-2/img-2.png')}" style="width: 30%;margin: 20px auto;" />
              Matemática</h3>
            <p>A <strong>MATEMÁTICA</strong> compreendida através da música.</p>
          </div>`,
        },
        {
          html: `
            <div class="right-box box-ludic">
              <h3>
                <img src="${require('../assets/section-2/img-4.png')}" style="width: 30%;margin: 20px auto;" />
                Lúdico</h3>
              <p>O aprendizado de forma <strong>LÚDICA</strong> e divertida para seus filhos.</p>
            </div>`,
        },
      ],
      breakpoints: {
        // 700px and up
        700: {
          itemsToShow: 2,
          snapAlign: 'center',
        },
        // 1024 and up
        1024: {
          itemsToShow: 2,
          snapAlign: 'start',
        },
      },
    };
  },
};
</script>
<style lang="scss" scoped>
  section {
    background: url(../assets/section-2/background.png) center center no-repeat;
    background-size: contain;
    padding-bottom: 80px;

    @media screen {
      @media (max-width:768px) {
        background: none;
        padding-bottom: 0;
      }
    }
  }
  h2 {
    color: #FFF;
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 136.523%; /* 43.687px */
    letter-spacing: -1.28px;
    width: 80%;
    margin: 0 auto;

    @media screen {
      @media (max-width:768px) {
        font-size: 25px;
      }
    }
    span {
      color: #FFBC00;
      font-family: Inter;
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: 136.523%;
      letter-spacing: -1.28px;
      text-transform: uppercase;
      text-wrap: nowrap;

      @media screen {
        @media (max-width:768px) {
          font-size: 28px;
        }
      }

      &:first-child {
        color: #0FF;
      }
    }
    strong {
      color: #0FF;
      font-family: Inter;
      font-size: 32px;
      font-style: normal;
      font-weight: 800;
      line-height: 136.523%;
      letter-spacing: -1.28px;
      display: block;
      text-transform: uppercase;

      @media screen {
        @media (max-width:768px) {
          font-size: 28px;
        }
      }
    }
  }

  .container-star {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 65px auto 80px;
    max-width: 1100px;

    @media screen {
      @media (max-width:768px) {
        display: block;

        img {
          width: 90vw;
          margin: 0 auto;
        }
      }
    }

    .left-column, .right-column {
      display: flex;
      flex-direction: column;
      flex: 0 0 20%;
      min-height: 500px;
    }

    .center-box {
      flex: 0 0 40%;
      text-align: center;
      line-height: 100px;
    }

    .left-box, .right-box {
      flex: 1;
      text-align: center;
      line-height: 50px;
      max-width: 250px;
    }

    h3 {
      text-align: left;
    }

    p {
      color: #FFF;
      text-align: left;
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 10px;

      strong {
        font-size: 24px;
        font-weight: 700;
      }
    }

    .box-vision, .box-logic,
    .box-math, .box-ludic {
      h3 {
          background: url(../assets/section-2/img-1.png) right center no-repeat;
          height: 103px;
          padding-right: 120px;
          line-height: 103px;
          font-size: 40px;
          font-weight: 900;
      }
    }

    .box-logic {
      h3 {
        background: url(../assets/section-2/img-2.png) left center no-repeat;
        padding: 0 0 0 125px;
      }
    }

    .box-math {
      h3 {
        background: url(../assets/section-2/img-3.png) right bottom no-repeat;
        padding-bottom: 65px;
      }
    }

    .box-ludic {
      h3 {
        background: url(../assets/section-2/img-4.png) left center no-repeat;
        padding: 0 0 0 125px;
      }
    }
  }
</style>
