<!-- eslint-disable max-len -->
<template>
  <section class="VM2L-section">
    <div class="inner-content clearfix">
      <h2>Conheça Rafael Syd: O Mestre por trás do Método <strong>VM2L</strong> do Curso <strong>Look Sonata</strong></h2>

      <img v-if="isMobile" src="@/assets/section-6/foto-polvo-rafael.png" alt="Foto Rafael" class="img-polvo" />
      <img v-if="isMobile" src="@/assets/section-6/avatar-rafael.png" alt="Avatar Rafael" class="img-avatar" />

      <div class="box-content box-1">
        <h3>👋🏻 Conheça Rafael Syd: O Mestre Por Trás do Método VM2L!</h3>
        <p>Rafael Syd, pai do pequeno Mathias, é filmaker de produções no Canal OFF, TV Globo e de celebridades do mundo da música brasileira, e acima de tudo, um observador apaixonado da arte em sua forma mais pura e fascinante. Há mais de duas décadas, este maestro visual tem capturado padrões e ritmos encantadores através de suas lentes cinematográficas, descobrindo a dança magnética entre imagem e som.</p>
      </div>

      <div class="box-content box-2">
        <h3>🕵🏻‍♂️ Uma Jornada de Descoberta e Inovação!</h3>
        <p>Durante sua jornada, a música lançou seu feitiço sobre Rafael, despertando uma curiosidade insaciável e profunda no artista. Após estudos em Composição e Arranjo Musical e de Percepção Musical na UFRGS, além do aprendizado de diversos instrumentos musicais. Rafael não apenas ouviu, mas ‘viu’ a música, percebendo uma oportunidade para intensificar e elevar a experiência audiovisual através do método VM2L, criando uma sinergia entre imagem e som.</p>
      </div>

      <div class="box-content box-3">
        <h3>↔️ Nasce o Método VM2L: Sua Ponte Para a Musicalização!</h3>
        <p>Uma criação inovadora de Rafael Syd, o VM2L é um passo a passo cuidadosamente elaborado que abre as portas do universo musical para todos.</p>
        <p>Desenhado para crianças, mas aberto a almas curiosas de todas as idades, o VM2L não apenas ensina; ele conecta, fazendo a ponte entre o som e a imagem, permitindo que a criança veja a música, sinta a imagem e embarquem em uma viagem sensorial inesquecível e transformadora.</p>
      </div>

      <strong class="txt-end">Mergulhe no mundo do <span style="text-wrap: nowrap;">Look Sonata</span> VM2L e veja onde a música e a imagem podem te levar</strong>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AboutTheCourse',
  data() {
    return {
      isMobile: window.matchMedia('(max-width: 768px)').matches,
    };
  },
};
</script>

<style lang="scss" scoped>
  section {
    background: url(@/assets/section-6/img-1.jpg) top center no-repeat;
    padding: 40px 0;
    @media screen {
      @media (max-width:768px) {
        background: #000;
      }
    }
  }
  .box-content {
    border-radius: 40px;
    background: radial-gradient(157.24% 123.22% at 47.19% -0.63%,
      rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.00) 100%);
    backdrop-filter: blur(21px);
    padding: 30px 15px 30px;
    margin: 0 30px 40px 30px;
    clear: both;

    h3 {
      color: #0FF;
      font-family: Manrope;
      font-size: 26px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 92.308% */
      text-align: left;
      margin-bottom: 20px;
    }

    p {
      color: #FFF;
      font-family: Manrope;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 133.333% */
      text-align: left;

      @media screen {
        @media (max-width:768px) {
          word-spacing: 3px;
          letter-spacing: 1px;
          line-height: 21px;
        }
      }
    }
    &.box-1 {
      width: 349.705px;
      padding-top: 70px;
    }
    &.box-2 {
      width: 726px;
    }
    &.box-3 {
      width: 737px;
      float: right;
    }

    @media screen {
      @media (max-width:768px) {
        width: 90vw !important;
        margin: 0 auto 20px;
      }
    }
  }
  h2 {
    text-align: center;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    line-height: 136.523%; /* 32.765px */
    letter-spacing: -0.96px;
    text-transform: uppercase;
    padding: 40px 0 60px;
    @media screen {
      @media (max-width:768px) {
        padding-top: 10px;
      }
    }
    strong {
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 900;
      line-height: 136.523%;
      letter-spacing: -0.96px;
      text-transform: uppercase;
      color: #FFBC00;
      text-wrap: nowrap;
      &:first-child {
        color: #0FF;
        text-wrap: unset;
      }
    }
  }
  .txt-end {
    clear: both;
    display: block;
    color: #0FF;
    font-family: Manrope;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 39px; /* 150% */
    max-width: 640px;
    float: right;
    margin: 0 30px 40px 30px;
  }

  .img-avatar, .img-polvo {
    display: block;
    margin: 0 20px 40px;
    width: 38vw;
    float: left;
  }

  .img-polvo {
    transform: scale(1.13);
    top: 19px;
    position: relative;
    left: 30px;
  }
</style>
