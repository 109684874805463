<!-- eslint-disable max-len -->
<template>
  <div>
    <section class="VM2L-section home">
      <div class="inner-content">
        <h1>Curso de Musicalização Infantil - <span>LOOK SONATA</span></h1>
        <h2>Transforme sua casa em um laboratório musical para seu filho(a)</h2>

        <a href="javascript:;" class="wrapper-video" @click="changeModal">
          <img src="@/assets/section-1/player.png" alt="Assista ao vídeo" v-if="!isMobile">
          <img src="@/assets/section-1/laptop-mobile.png" alt="Assista ao vídeo" v-if="isMobile">
        </a>

        <p>Método replicável nas canções que já são sucesso em sua casa!</p>

        <a href="javascript:;" @click="showModalForm = true" class="action-button">
          quero agora que meu filho(a) brinque<br />
          em um ambiente MúsicaL INOVADOR
        </a>
        <p class="leg">Compra 100% segura. Receba acesso imediatamente após a confirmação do pagamento</p>

        <ul class="lst-payment-methods">
          <li class="hotmart"><img src="@/assets/section-1/hotmart.png" alt="Hotmart"></li>
          <li class="mastercard"><img src="@/assets/section-1/mastercard.png" alt="Mastercard"></li>
          <li class="visa"><img src="@/assets/section-1/visa.png" alt="Visa"></li>
          <li class="boleto"><img src="@/assets/section-1/boleto.png" alt="Boleto"></li>
          <li class="paypal"><img src="@/assets/section-1/paypal.png" alt="Paypal"></li>
          <li class="pix"><img src="@/assets/section-1/pix.png" alt="Pix"></li>
        </ul>
      </div>
      <div v-if="showModal">
        <iframe id="modal-video" width="560" height="315" src="https://www.youtube.com/embed/nWI6ge_MaDU?si=nWI6ge_MaDU&autoplay=1&controls=0&autohide=1&showinfo=0&" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <a href="javascript:;" @click="changeModal" class="btn-close-video" title="Fechar vídeo">X</a>
      </div>
    </section>
    <TheMethod />
    <WhoIsItFor />
    <WhatBenefits @openModal="showModalForm = true" />
    <AboutTheCourse />
    <TheCreator />
    <SayingAbout />
    <InsuranceView @openModal="showModalForm = true" />
    <FooterComp />
    <ModalForm v-if="showModalForm" @close="showModalForm = false" />
  </div>
</template>

<script>
import TheMethod from '@/views/TheMethod.vue';
import WhoIsItFor from '@/views/WhoIsItFor.vue';
import WhatBenefits from '@/views/WhatBenefits.vue';
import AboutTheCourse from '@/views/AboutTheCourse.vue';
import TheCreator from '@/views/TheCreator.vue';
import SayingAbout from '@/views/SayingAbout.vue';
import InsuranceView from '@/views/InsuranceView.vue';
import FooterComp from '@/components/FooterComp.vue';
import ModalForm from '@/components/ModalForm.vue';

export default {
  name: 'HomeView',
  components: {
    TheMethod,
    WhoIsItFor,
    WhatBenefits,
    AboutTheCourse,
    TheCreator,
    SayingAbout,
    InsuranceView,
    FooterComp,
    ModalForm,
  },
  data() {
    return {
      showModalForm: false,
      showModal: false,
      isMobile: window.matchMedia('(max-width: 768px)').matches,
    };
  },
  methods: {
    changeModal() {
      this.showModal = !this.showModal;
    },
    changeModalForm() {
      this.showModalForm = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  #modal-video,
  .btn-close-video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1;
  }

  .btn-close-video {
    width: 50px;
    height: 50px;
    display: block;
    z-index: 2;
    background: #fff;
    border-radius: 50%;
    text-decoration: none;
    line-height: 50px;
    top: 5%;
    left: auto;
    right: 5%;
    color: #000;
  }
  .inner-content {
    background: url(@/assets/section-1/background.jpg) top center no-repeat;
    padding-bottom: 80px;
    @media screen {
      @media (max-width:768px) {
        background: none;
        padding-bottom: 40px;
      }
    }

    .leg {
      opacity: 0.6;
    }
  }

  .wrapper-video {
    width: 80vw;
    max-width: 760px;
    margin: 0 auto;
    display: block;
    img {
      width: 100%;
      margin: 0 auto;
      cursor: pointer;
    }
  }

  h1 {
    color:#FFF;
    text-align: center;
    font-family: Inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 136.523%; /* 49.148px */
    letter-spacing: -1.44px;
    text-transform: uppercase;
    padding: 48px 0 7px;
    text-shadow: 0 2px 0px #000;

    @media screen {
      @media (max-width:768px) {
        font-size: 28px;
      }
    }

    span {
      color: #FFBC00;
      text-wrap: nowrap;
    }
  }
  p {
    max-width: 60%;
    margin: 20px auto;

    @media screen {
      @media (max-width:768px) {
        max-width: 85%;
        font-size: 18px;
        line-height: 25px;
      }
    }
  }
  h2 {
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 136.523%; /* 43.687px */
    letter-spacing: -1.28px;
    margin-bottom: 52px;
    text-shadow: 0 2px 0px #000;
    word-spacing: 5px;
    @media screen {
      @media (max-width:768px) {
        font-size: 21px;
        text-shadow: -1px 1px 7px rgba(255,255,255,0.5);
      }
    }
  }

  .action-button {
    border-radius: 16px;
    border: 2px solid #000;
    background: linear-gradient(90deg, #3E66AA 0%, #00276C 100%);

    color: #0FF;
    text-align: center;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 145%; /* 34.8px */
    letter-spacing: -0.96px;
    text-transform: uppercase;
    height: auto;
    margin-bottom: 20px;
    margin-top: 0;

    @media screen {
      @media (max-width:768px) {
        font-size: 16px;
      }
    }
  }

  .lst-payment-methods {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 667px;
    margin: 0 auto;

    @media screen {
      @media (max-width:768px) {
        width: 90vw;
      }
    }

    img {
      width: 100%;
      height: auto;
    }

    li {
      margin: 15px;

      @media screen {
        @media (max-width:768px) {
          margin: 0 auto;
        }
      }

      &.hotmart {
        width: 104px;
        height: 39px;
      }
      &.mastercard {
        width: 59px;
        height: 59px;
      }
      &.visa {
        width: 63px;
        height: 21px;
      }
      &.boleto {
        width: 60px;
        height: 39px;
      }
      &.paypal {
        width: 117px;
        height: 33px;
      }
      &.pix {
        width: 84px;
        height: 30px;
      }

      img {
        display: block;
        width: 100%;

        @media screen {
          @media (max-width:768px) {
            width: 70%;
            display: block;
            margin: 0 auto;
          }
        }
      }
    }
  }
}

</style>
