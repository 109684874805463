<!-- eslint-disable max-len -->
<template>
  <section class="VM2L-section">
    <div class="inner-content">
      <h2>O que estão dizendo do método <strong>VM2L</strong></h2>

      <carousel :breakpoints="breakpoints" v-if="isMobile">
        <slide v-for="slide in slides" :key="slide">
          <div class="carousel__item" v-html="slide.text"></div>
        </slide>

        <template #addons>
          <navigation />
          <pagination />
        </template>
      </carousel>

      <div class="box-messages" v-if="!isMobile">
        <div class="box-1">
          <p>
            Bom dia. Inacreditável fazerem minha filha de 3 enxergar a música A roda do ônibus gira dessa forma tão única e responsiva. Super feliz pela experiência.<br />
            <span>Claudia</span>
          </p>
        </div>

        <div class="box-2">
          <p>
            Oláaa!! Parabéns pelo método. Me chamo Paulo e meu filho tem 4 aninhos. Nunca imaginei que ele fosse acompanhar uma partitura musical logo de primeira. Esse passo a passo de vocês realmente faz sentido e ajuda muito a criança.
            Gratidão eternas, Abss, sucesso.<br />
            <span>Paulo</span>
          </p>
        </div>

        <div class="box-3">
          <p>
            Passando para agradecer pela experiência com meus filhos. Super demais agora eles acompanharem o que se movimenta nas músicas e poder aplicar em outras músicas. Genial. AbsSabrina<br />
            <span>Sabrina</span>
          </p>
        </div>

        <div class="box-4">
          <p>
            Realmente um dos meus desafios como pai era encontrar alguma brincadeira que eu pudesse fazer com a minha filha que agregasse conhecimento para ela, além do entretenimento. Agora quando chego em casa do trabalho, há algo relevante que podemos brincar juntos. Obrigado galera da <span style="text-wrap: nowrap;">Look Sonata</span>. Sucesso.<br />
            <span>Jeferson</span>
          </p>
        </div>

        <div class="box-5">
          <p>
            Olá, meu nome é Luísa, mãe do Gabriel. Sempre queria propor algo um curso de iniciação musical para ele, mas sempre esbarrava na dificuldade de se deslocar pela cidade pois moro em São Paulo. Que sorte encontrar vocês na internet. Bj, obrigada e sucesso.<br />
            <span>Luísa</span>
          </p>
        </div>

        <div class="box-6">
          <p>
            Queria agradecer ao time do VM2L por esse método que transformou o jeitinho do meu filho em perceber as músicas infantis em casa.<br />
            <span>Jaqueline</span>
          </p>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import 'vue3-carousel/dist/carousel.css';
import {
  Carousel,
  Slide,
  Pagination,
  Navigation,
} from 'vue3-carousel';

export default {
  name: 'WhatBenefits',
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data() {
    return {
      isMobile: window.matchMedia('(max-width: 768px)').matches,
      slides: [
        {
          text: `<p>
            Bom dia. Inacreditável fazerem minha filha de 3 enxergar a música A roda do ônibus gira dessa forma tão única e responsiva. Super feliz pela experiência.<br />
            <span>Claudia</span>
          </p>`,
        },
        {
          text: `<p>
                Oláaa!! Parabéns pelo método. Me chamo Paulo e meu filho tem 4 aninhos. Nunca imaginei que ele fosse acompanhar uma partitura musical logo de primeira. Esse passo a passo de vocês realmente faz sentido e ajuda muito a criança.
                Gratidão eternas, Abss, sucesso.<br />
                <span>Paulo</span>
              </p>`,
        },
        {
          text: `<p>
                Passando para agradecer pela experiência com meus filhos. Super demais agora eles acompanharem o que se movimenta nas músicas e poder aplicar em outras músicas. Genial. AbsSabrina<br />
                <span>Sabrina</span>
              </p>`,
        },
        {
          text: `<p>
                Realmente um dos meus desafios como pai era encontrar alguma brincadeira que eu pudesse fazer com a minha filha que agregasse conhecimento para ela, além do entretenimento. Agora quando chego em casa do trabalho, há algo relevante que podemos brincar juntos. Obrigado galera da <span style="text-wrap: nowrap;">Look Sonata</span>. Sucesso.<br />
                <span>Jeferson</span>
              </p>`,
        },
        {
          text: `<p>
              Olá, meu nome é Luísa, mãe do Gabriel. Sempre queria propor algo um curso de iniciação musical para ele, mas sempre esbarrava na dificuldade de se deslocar pela cidade pois moro em São Paulo. Que sorte encontrar vocês na internet. Bj, obrigada e sucesso.<br />
              <span>Luísa</span>
            </p>`,
        },
        {
          text: `<p>
              Queria agradecer ao time do VM2L por esse método que transformou o jeitinho do meu filho em perceber as músicas infantis em casa.<br />
              <span>Jaqueline</span>
            </p>`,
        },
      ],
      breakpoints: {
        // 700px and up
        700: {
          itemsToShow: 2,
          snapAlign: 'center',
        },
        // 1024 and up
        1024: {
          itemsToShow: 2,
          snapAlign: 'start',
        },
      },
    };
  },
};
</script>
<style lang="scss" scoped>
  section {
    .inner-content {
      background: url(../assets/section-7/background.png) center top no-repeat;
      padding: 60px 0;
      @media screen {
        @media (max-width:768px) {
          background-size: 100%;
        }
      }
    }

    p {
      color: #FFF;
      font-family: Londrina Solid;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: left;
    }
    span {
      color: #FFF;
      font-family: Londrina Solid;
      font-size: 22px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }
  }

  .box-messages {
    display: flex;
    flex-wrap: wrap;
    div {
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: contain;
      width: 100vw;
      height: 100vh;
      text-align: left;
      display: flex;
      justify-content: center;
      align-items: center;
      @media screen {
        @media (max-width:768px) {
          background: #073d6e !important;
          max-width: 80% !important;
          flex: 0 0 100% !important;
          margin: 20px auto !important;
          border-radius: 20px;
          max-height: 100% !important;
          height: auto;
          padding: 25px 0;
          border: 2px solid;
          p {
            padding: 0 !important;
          }
        }
      }
    }

    .box-1 {
      background-image: url(../assets/section-7/img-1.png);
      max-width: 632px;
      max-height: 252px;
      flex: 0 0 51%;
      margin-left: 14%;

      p {
        width: 65%;
        margin: 0 auto;
        padding-left: 100px;
        padding-bottom: 26px;
      }
    }

    .box-2 {
      background-image: url(../assets/section-7/img-2.png);
      max-width: 407px;
      max-height: 418px;
      flex: 0 0 33%;
      margin-left: 0;

      p {
        width: 58%;
        padding-bottom: 50px;
      }
    }

    .box-3 {
      background-image: url(../assets/section-7/img-3.png);
      max-width: 244px;
      max-height: 441px;
      flex: 0 0 20%;
      margin-left: 3%;
      margin-top: -16%;
      p {
        width: 57%;
      }
    }

    .box-4 {
      background-image: url(../assets/section-7/img-4.png);
      max-width: 594px;
      max-height: 417px;
      flex: 0 0 47%;
      margin-top: -12%;
      p {
        width: 65%;
        padding-left: 40px;
        span {
          float: right;
        }
      }
    }

    .box-5 {
      background-image: url(../assets/section-7/img-5.png);
      max-width: 710px;
      max-height: 304px;
      flex: 0 0 56%;
      margin-left: 3%;
      margin-top: 2%;
      p {
        width: 60%;
      }
    }

    .box-6 {
      background-image: url(../assets/section-7/img-6.png);
      max-width: 455px;
      max-height: 315px;
      flex: 0 0 36%;
      margin-left: 3%;
      margin-top: -2%;

      p {
        width: 57%;
        padding-bottom: 60px;
      }
    }
  }

  h2 {
    color:#FFF;
    text-align: center;
    font-family: Inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 900;
    line-height: 136.523%; /* 49.148px */
    letter-spacing: -1.44px;
    text-transform: uppercase;
    margin-bottom: 40px;

    @media screen {
      @media (max-width:768px) {
        font-size: 28px;
        margin-bottom: 0px;
      }
    }

    strong {
      color: #0FF;
      font-size: 48px;
      font-style: normal;
      font-weight: 900;
      line-height: 136.523%;
      letter-spacing: -1.92px;
      text-transform: uppercase;

      @media screen {
        @media (max-width:768px) {
          font-size: 35px;
        }
      }
    }
  }
</style>
