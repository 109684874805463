<template>
  <div class="footer">
    <h1><img src="../assets/section-1/logo.png" alt="Logo Musicalização Infantil"></h1>
    <p>
      Copyright© - Musicalização Infantil. Todos os direitos reservados.
    </p>
  </div>
</template>

<script setup></script>

<style scoped lang="scss">
p {
  color: #FFF;
  font-family: Londrina Solid;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  margin: 20px auto;
}

img {
  max-width: 300px;
  display: block;
  margin: 0 auto;
}
</style>
