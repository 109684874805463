<!-- eslint-disable max-len -->
<template>
  <section class="VM2L-section">
    <div class="inner-content">
      <img src="../assets/section-8/img-1.png" alt="Garantia de 7 dias Incodicional">
      <h2>Risco <strong>zero</strong> com a garantia de 7 dias.</h2>
      <h3>Assim que entrar no Curso de musicalização infantil <span style="text-wrap: nowrap;">Look Sonata</span>,<br /> você terá acesso imediato a todos os conteúdos do Portal.</h3>
      <p>Durante 7 dias a partir da compra, você poderá consumir todo conteúdo que quiser e interagir com a comunidade.<br />
      Se dentro desse período você se arrepender da compra, basta pedir o reembolso que devolveremos cada centavo. sem ressentimentos.</p>

      <a href="javascript:;" @click="this.$emit('openModal');" class="action-button">
        <strong>Inscrições abertas aqui</strong>
        Não perca essa oportunidade
      </a>
    </div>
  </section>
</template>
<style lang="scss" scoped>
  section {
    background: linear-gradient(180deg, #012667 0%, #B517FF 169.42%);
    padding: 60px 0;
    @media screen {
      @media (max-width:768px) {
        padding: 0 0 60px;
      }
    }
  }
  .box-content {
    border-radius: 40px;
    background: radial-gradient(157.24% 123.22% at 47.19% -0.63%,
      rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.00) 100%);
    backdrop-filter: blur(21px);
  }
  h2 {
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 136.523%; /* 54.609px */
    letter-spacing: -1.6px;
    @media screen {
      @media (max-width:768px) {
        font-size: 32px;
        max-width: 90vw;
        margin: 12px auto 30px;
        line-height: 25px;
     }
    }
    strong {
      color: #FFF;
      font-size: 64px;
      font-style: normal;
      font-weight: 700;
      line-height: 136.523%;
      letter-spacing: -2.56px;
      @media screen {
        @media (max-width:768px) {
          font-size: 45px;
        }
      }
    }
  }
  h3 {
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 136.523%; /* 32.765px */
    letter-spacing: -0.96px;
    margin: 20px 0;
    @media screen {
      @media (max-width:768px) {
        font-size: 20px;
      }
    }
  }
  p {
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 136.523%; /* 27.305px */
    letter-spacing: -0.8px;
    max-width: 80%;
    margin: 0 auto;

    @media screen {
      @media (max-width:768px) {
        font-size: 16px;
      }
    }
  }
  .action-button {
    line-height: normal;
    font-weight: 400;
    font-size: 20px;
    height: auto;
    strong {
      font-weight: 700;
      font-size: 24px;
      margin-bottom: 5px;
      display: block;
    }
  }
</style>
