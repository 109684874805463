<!-- eslint-disable max-len -->
<template>
  <section class="VM2L-section">
    <div class="inner-content">
      <h2>SOBRE O CURSO DE MUSICALIZAÇÃO INFANTIL <span style="text-wrap: nowrap;">look sonata</span></h2>

      <div class="box-reading">
        <img v-if="isMobile" src="@/assets/section-5/mobile-1.png" alt="Foto de um Menino" />
        <p>O curso Look Sonata se utiliza de desenhos animados explorando todos os elementos do som de forma visual, bem didática, com cores, gráficos e formas geométricas simulando a <strong>leitura musical</strong> como se fossem partituras.</p>
      </div>
      <div class="box-theory">
        <img v-if="isMobile" src="@/assets/section-5/mobile-2.png" alt="Foto de um Menino" />
        <p>A criança se familiariza com a <strong>teoria musical</strong> ao mesmo tempo em que canta suas canções favoritas. Assim, ela embarca em uma experiência, na qual “enxerga” o som: um processo até então viável  apenas para quem tem acesso às escolas tradicionais de música.</p>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'AboutTheCourse',
  data() {
    return {
      isMobile: window.matchMedia('(max-width: 768px)').matches,
    };
  },
};
</script>
<style lang="scss" scoped>
  section {
    @media screen {
      @media (max-width:768px) {
        .inner-content {
          width: calc(100vw - 40px);
          padding: 0 20px;

          div {
            padding: 0;
            img {
              margin: 30px 0;
              display: block;
            }
          }
        }
      }
    }
  }

  h2 {
    text-align: center;
    font-size: 36px;
    font-style: normal;
    font-weight: 900;
    line-height: 136.523%;
    letter-spacing: -1.44px;
    text-transform: uppercase;
    position: relative;
    top: 80px;

    @media screen {
      @media (max-width:768px) {
        font-size: 28px;
        top: 20px;
        margin: 20px auto 50px;
      }
    }
  }

  p {
    color: #D9D9D9;
    text-align: justify;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 136.523%; /* 32.765px */
    letter-spacing: -0.96px;

    @media screen {
      @media (max-width:768px) {
        font-size: 18px;
      }
    }

    strong {
      font-size: 24px;
      font-style: normal;
      font-weight: 800;
      line-height: 136.523%;
      letter-spacing: -0.96px;

      @media screen {
        @media (max-width:768px) {
          font-size: 18px;
        }
      }
    }
  }
  .inner-content {
    height: 100%;
    position: relative;
    padding-bottom: 325px;
    div {
      display: flex;
      align-items: center;
      padding: 50px 0 0;

      @media screen {
        @media (max-width:768px) {
          display: block;
          border: 1px solid yellow;
          width: calc(100% - 20px);
          padding: 10px !important;
          margin: 20px auto !important;
        }
      }
    }
  }
  .box-reading {
    background: url(@/assets/section-5/img-1.png) center top no-repeat;
    width: 100%;
    min-height: 492px;
    justify-content: end;
    text-align: justify;
    @media screen {
      @media (max-width:768px) {
        background: none;

        img {
          display: block;
          margin: 0 auto;
          width: 100%;
        }
      }
    }

    p {
      flex: 0 0 50%;
      margin-right: 10%;

      @media screen {
        @media (max-width:768px) {
          margin: 0;
        }
      }
    }
  }

  .box-theory {
    background: url(@/assets/section-5/img-2.png) center top no-repeat;
    width: 100%;
    text-align: justify;
    min-height: 453px;
    padding: 0;
    position: absolute;
    bottom: 0;
    left: 0;

    @media screen {
      @media (max-width:768px) {
        background: none;
        display: block;
        position: static;

        img {
          display: block;
          margin: 0 auto;
          width: 100%;
        }
      }
    }
    p {
      flex: 0 0 43%;
      margin-left: 40px;
      justify-content: center;
      margin-top: 180px;

      @media screen {
        @media (max-width:768px) {
          flex: 0 0 90%;
          margin: 0;
        }
      }
    }
  }
</style>
