 <template>
  <!-- eslint-disable -->
  <div class="wrapper-iframe">
    <a href="javascript:;" class="modal-close" @click="closeModal">X</a>
    <iframe src="https://rafaelvideos.activehosted.com/f/3" frameborder="0"></iframe>
  </div>
  <!-- eslint-disable -->
</template>

<script>
export default {
  data() {
    return {
      formData: {
        name: {
          value: '',
          error: false,
        },
        email: {
          value: '',
          error: false,
        },
        phone: {
          value: '',
          error: false,
        },
      },
    };
  },
  mounted() {
    // const recaptchaScript = document.createElement('script');
    // recaptchaScript.setAttribute('src', 'https://rafaelvideos.activehosted.com/f/embed.php?id=3');
    // document.body.appendChild(recaptchaScript);
  },
  methods: {
    closeModal() {
      this.$emit('close', false);
    },
  },
};
</script>

<style scoped>
.modal, .wrapper-iframe {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #000;
  font-size: 15px;
  background: #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-decoration: none;
  z-index: 5;
}
.modal-content {
  background-color: #012667;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 40px 20px 20px;
  width: 100vw;
  max-width: 320px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

h2 {
  font-size: 1.1rem;
  margin-bottom: 5px;
  color: #fff;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 0px;
  color: #fff;
  text-align: left;
  font-size: 14px;
}

input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 1rem;
}

button {
  background-color: #4caf50;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

button:hover {
  background-color: #45a049;
}

.error {
  border: 1px solid #fffff3;
}

iframe {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  left: 0;
}
</style>
